
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TextBox from "@/components/TextBox/Index.vue";

@Component({
  components: {
    TextBox,
  },
})
export default class JustLogsModal extends Vue {
  @Prop({ required: true }) readonly showDialog!: boolean;

  private active = false;
  private description = "";

  sendDescription() {
    this.active = false;
    this.$emit("send", this.description);
    this.description = "";
    this.$mixpanel.track("Mantener documentacion", {
      Actividad: this.$store.state.DocumentModule.DocumentState.SelectedDocument
        .activityName,
      Justificacion: this.description,
    });

  }

  @Watch("showDialog")
  show(): void {
    this.active = this.showDialog;
  }

  @Watch("active")
  dialogClose(): void {
    if (this.active === false) {
      this.$mixpanel.track("Cerrar - modal mantener documentacion");
      this.$emit("close");
    }
  }
}
